<template>
  <fieldset>
    <DocFields
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields-bottom>
        <UniversalSelectField
          class="form-control"
          :modelValue="data.settlement"
          @update:modelValue="updateVector($event)"
          @filter="updateFilterWithDelay"
          data-qa="settlement"
          :keyProp="'externalId'"
          :displayProp="'name'"
          :options="vectors"
          :can-find="true"
        >
          <label class="label">Поселение</label>
        </UniversalSelectField>
      </template>
    </DocFields>
  </fieldset>
</template>

<script>
import DocSection5 from "../../../models/documents/docSection5";
import DocFields from "./DocFields";
import { toRefs } from "vue";
import { useVectorSettlement } from "@/hooks/vector";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";

export default {
  emits: ["update:modelValue", "update:vector"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocSection5(),
    },
    documentTypeId: {
      type: Number,
      required: true,
    },
    documentRegNum: {
      type: String,
      required: false,
    },
  },
  components: {UniversalSelectField, DocFields },
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);
    const { vectors, fetchWithDelay } = useVectorSettlement();

    function updateFilterWithDelay(value) {
      if (typeof value === "string") {
        updateVector(null);
      } else updateVector(value);
      fetchWithDelay(value);
    }

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    function updateVector(value) {
      update("settlement", value);
      emit("update:vector", value);
    }

    return { data, update, vectors, updateFilterWithDelay, updateVector };
  },
};
</script>
